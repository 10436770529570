<template>
  <div class="container-small">
    <b-overlay :show="busy" rounded="sm">
      <b-card class="card" header="Prihlásenie">
        <b-form @submit.prevent="onSubmit">
          <b-form-group
              id="email-label"
              label="Email"
              label-for="email">
            <b-form-input
                id="email"
                v-model="credential.email"
                type="email"
                autocomplete="email"
                required
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="current-password-label"
              label="Heslo"
              label-for="current-password">
            <b-form-input
                id="current-password"
                v-model="credential.password"
                autocomplete="current-password"
                type="password"
                required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="secondary" class="w-100 text-center">Prihlásiť</b-button>
          <a v-if="!demoDomain" :href="`http://` + demoDomainLink" >
            <b-button variant="outline-primary" size="sm" class="w-100 block text-center mt-3 demo font-weight-light">Vyskúšajte si bezplatné demo <strong class="font-weight-bold">Fudoma</strong></b-button>
          </a>
        </b-form>
      </b-card>
    </b-overlay>
    <router-link to="/login/forgot" class="link mt-3">Zabudli ste heslo?</router-link>
    <div class="version mt-2">{{ env }}</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import load from "@/plugins/app/load"

export default {
  name: 'Home',
  computed:{
    ...mapGetters('wAuth', ['isLoggedIn', 'user']),
    ...mapGetters('venues', ['venues']),
    demoDomain() {
      return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
    },
    demoDomainLink() {
      return process.env.VUE_APP_DEMO_DOMAIN
    },
  },
  data() {
    return {
      busy: false,
      env: process.env.NODE_ENV === 'development' ? 'development' : process.env.VUE_APP_VERSION,
      credential: {
        email: '',
        password: ''
      }
    }
  },
  async mounted() {
    if (this.isLoggedIn)
      await this._login()

    if(this.demoDomain)
      this.login({email: 'demo@fudoma.com', password: 'demo1234'}).then( async _ => {
        await this._login()
      })
  },
  methods: {
    ...mapActions('wAuth', ['login']),
    onSubmit () {
      this.busy = true
      this.login(this.credential).then( async _ => {
        await this._login()
      }).catch(e => {
        this.busy = false
        this.$wToast.error(e)
      })
    },
    async _login() {
      this.$wToast.success('Vitaj!', true)
      await load.loadAllData()

      if(!this.user.company || !this.venues.length)
        await this.$router.push('/login/onboarding')
      else
        await this.$router.push('/app/daily')
    }
  }
}
</script>
<style lang="scss" scoped>
.container-small {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  * {
    text-align: left;
  }
}
.form-group:nth-child(2){
  border-bottom: 1px solid #F1F1F1;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.demo {

}

.link {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #A6A6A6;
  text-decoration: underline;
}

.version {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #A6A6A6;
  text-align: center;
}
</style>
